<template>
  <WsMain class="pb-80">
    <WsStateForm
      :fields="_fields"
      v-model="state"
    ></WsStateForm>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {
        ...this.$store.state.auth.currentUser,
      },
    };
  },
  computed: {
    _fields() {
      const _fields = {
        name: this.$store.state.stone_model.admin.fields.name,
        avatar: this.$store.state.stone_model.admin.fields.avatar,
      };
      return _fields;
    },
  },
  methods: {
    async $_onSubmit() {
      this.$store.dispatch("app/startPageLoading");
      try {
        const postData = this.$o_o.$h.model.getFormatedStates(
          this.fields,
          this.state,
          true
        );
        const res = await this.$axios.patch("/auth/user", postData);
        this.$store.dispatch("auth/setCurrentUser", res.data.user);
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>

<style>
</style>